import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';
import { languages, PageType, link } from '../interfaces';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  // {path: '', component: LandingPageComponent },
  // {path: 'clases', component: DancesComponent },
  // {path: 'page', component: PageComponent },
  public PageType: PageType
  public links: link[] = [
  ]
  public language = this.global.language

  public page
  public opened
  constructor(private global: GlobalService, private router: Router, private message: MessageService) {
    this.getSidebarJSON()
  }

  ngOnInit(): void {
  }

  async changeRoute(link: link) {

    this.opened=link
    let url = ''
    if (link.type != 'page') {
      url = link.url
      
      this.message.sendMessage('mediaChange',{largeImage:link.largeImage})
      
    }
    else {
      //this.getPage(link.url,link)
      url = 'page/' + link.url + '/' + ((link.name[this.language]).toLowerCase()).split(' ').join('_')
    }
    this.router.navigateByUrl(url)
    this.closeMenu()

  }

  async getSidebarJSON() {
    await this.global.HTTPReq('GET', this.global.api, "filesystem/getfile/layout/sidebar.json").then((res) => {
      this.links = res
      //console.log(this.links)
    })
    this.global.sidebar={}
    for(let link of this.links){
      this.global.sidebar[link.url]=link.largeImage

    }
  }
  async closeMenu(){
    this.message.sendMessage('closeMenu')
  }
  
}
