<div class="reviews">
    <ng-container *ngFor="let review of reviews; let i = index;">
        
        <div class="review"  [class.left]="i % 2 != 0" >
            <ng-container  *ngIf="review && review.img && review.img.split('.')[1] && ['jpg','gif','png','svg'].indexOf((review.img.split('.')[1]).toLowerCase()) !=-1 ">
                <div   class="file" [style.background-image]="'url('+(review.img.indexOf('assets') !=-1 ?review.img:api+'filesystem/getfile/reviews/'+review.img+'/medium1')+')'" alt="" srcset=""></div>
            </ng-container>
            <div class="name">{{review.name}}</div>
            <div class="text">{{review.text}}</div>
        </div>
        <div class="sep" ></div>
    </ng-container>
    <div class="revForm">
        <div class="title">{{!submited.sent ? 'Want to get featured?' : (submited.success?'Review sent successful. Thank you for leaving review.':'Can`t submit review. Please try again later.')}}</div>

        <ng-container *ngIf="!submited.success">
            <div class="inputs">
                <div class="half nameField">
                    <div class="fieldTitle">NAME</div>
                    <input [(ngModel)]="review.name" class="fullHidden" type="text">
                </div>
                <div class="half imageFile">
                    <div class="fieldTitle">PICTURE</div>
                    <label class="custom-file-upload fullHidden">
                        <input [(ngModel)]="image" (change)="uploadImage($event)"  type="file"/>
                        {{image?image:'Upload Image'}}
                    </label>
                </div>
                <div class="full">
                    <div class="fieldTitle">REVIEW</div>
                    <textarea class="fullHidden" name="" [(ngModel)]="review.text" id="" cols="30" rows="10"></textarea>
                </div>
    
            </div>
            <div class="sendButtion link" (click)="submitReview()" >SEND</div>
        </ng-container>
        
       
    </div>

</div>