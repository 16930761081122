import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import { MessageService } from 'src/app/message.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {
  @Input('id') public id: any;
  @Input('title') public title: 'hidden'|'show'='show';

    message: any;
    subscription: Subscription;
  
    public language = 'EN';
  
    public opened=false;
    public count=0;
  
    public page = <any>{};
  
    public pageId;

    constructor(private route: ActivatedRoute, private global: GlobalService, private messageService: MessageService) {
  
      this.route.paramMap
        .subscribe(params => {
          //console.log(params['params'][params.keys[0]])
          let pageId = params['params'][params.keys[0]];
          this.pageId=pageId
          let orderIDHashed=''
          let orderID = params['params'][params.keys[1]];
  
          this.route.queryParams.subscribe(params => {
            if(params['data']){
             
              let OrderInfo=params['data']
              let res=(decodeURIComponent(atob(OrderInfo))).split('&')
              for(let key of res){
                if(key.split('=')[0]=='orderid'){
                  orderIDHashed=key.split('=')[1]
                //  console.log(orderIDHashed,orderID)
                  if(orderIDHashed!=orderID){
                    //console.log('didntMatch')
                    return
                  }
                  
                }
              }
              
            }
        
              });
          // if(this.pageId==1){          
          //  // console.log('make api call to change statuss to payed on order using id')
          //   //make api call to change statuss to 'payed' on order using id
  
          //   this.global.HTTPReq("POST",this.global.api,'orders/paymentStatuss',{
          //     orderIds:[orderIDHashed],
          //     paymentStatuss:2
          //   })
          //   console.log('attīra grozu')
          //   this.global.cart=[]
          //   this.global.CookieService("SET",{name:"cart",value:''})
          // }
          // if(this.pageId==2){
          //  // console.log('make api call to change statuss to NotPayed on order using id')
          //   //make api call to change statuss to 'not payed' on order using id
          //   this.global.HTTPReq("POST",this.global.api,'orders/paymentStatuss',{
          //     orderIds:[orderIDHashed],
          //     paymentStatuss:1
          //   })
          // }
          this.page={}
           this.getPage(pageId)
  
        })
       
  
  
      this.subscription = this.messageService.getMessage().subscribe(message => {
        this.message = message;
        if (this.message['text'] == 'UpdateLang') {
          this.language = this.message['element'];
          //console.log('maina', this.language)
        }
      });
  
      this.language = this.global.language
  
    }
  
    async ngOnInit() {
      if(this.id){
        this.pageId=this.id
        this.getPage(this.pageId)
      }
      window.scrollTo(0, 0)
    }
  
  
    async getPage(pageId?){
        let para = { id: pageId,type:1,public:true }
      this.global.HTTPReq('POST', this.global.api, 'pages/getPages', para).then((result) => {
        this.page=result[0]
        if(result && result[0]){
          this.messageService.sendMessage('mediaChange',{
            pageRoute:this.page.seo.meta[this.language],
            image: this.page.image,
            title:this.page.title[this.language],
            shortDesc:this.page.seo.shortDesc[this.language],
            largeImage:this.page.seo['layoutFull']
            
          })
        }
        
        //console.log('seit',this.page)
      })
    }
  
  }
  
