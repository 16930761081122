import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { languages } from 'src/app/interfaces';
import { MessageService } from 'src/app/message.service';

export interface file{
  title:languages,
  file:string
}
export interface media{
  title:languages,
  media:file[]
}


@Component({
  selector: 'app-galery',
  templateUrl: './galery.component.html',
  styleUrls: ['./galery.component.scss']
})
export class GaleryComponent implements OnInit {
  public api=this.global.api
  public language=this.global.language
  public galery:media[]=[
  ]

  public videoPlayers={}
  public ios=false
  constructor( private global: GlobalService, private messageService: MessageService) { }

 async ngOnInit() {
   this.ios=await this.global.iOS()
    await this.getGaleryJSON()
    setTimeout(()=>{
      this.messageService.sendMessage('mediaChange',{
        pageRoute:'Galery',
        shortDesc:'',
        title:'',
        largeImage:true,
        image:'/assets/video/2.mp4'
        
      })
    },1000)
    //console.log(JSON.stringify(this.galery))
  }
  playVideo(video){
    if(this.videoPlayers[video] && this.videoPlayers[video]['playing']){
      this.pauseVideo(video)
      return
    }
    for(let video of Object.keys(this.videoPlayers)){
      this.videoPlayers[video].pause()
      this.videoPlayers[video]['playing']=false
    }
    let videoElement
    if(!this.videoPlayers[video]){
      this.videoPlayers[video]=document.getElementById(video)
      videoElement= this.videoPlayers[video]
    }
   this.videoPlayers[video].play();
    this.videoPlayers[video]['playing']=true
  }
  pauseVideo(video){
    this.videoPlayers[video].pause();
    this.videoPlayers[video]['playing']=false
   }

  async getGaleryJSON() {
    this.galery= await this.global.HTTPReq('GET', this.global.api, "filesystem/getfile/layout/galery.json")
    this.galery.reverse()
  }

}
