import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlogsComponent } from './middle/blogs/blogs.component';
import { ContactusComponent } from './middle/contactus/contactus.component';
import { DancesComponent } from './middle/dances/dances.component';
import { GaleryComponent } from './middle/galery/galery.component';
import { LandingPageComponent } from './middle/landing-page/landing-page.component';
import { PageComponent } from './middle/page/page.component';
import { ReviewsComponent } from './middle/reviews/reviews.component';


const routes: Routes = [
  {path: '', component: LandingPageComponent },
  {path: 'clases', component: DancesComponent },
  {path: 'galery', component: GaleryComponent },
  {path: 'reviews', component: ReviewsComponent },
  {path: 'contactUs', component: ContactusComponent },
  {path: 'blog', component: BlogsComponent },
  { path:'blog/:keyword',component: BlogsComponent},
  { path:'blog/:id/:seo',component: BlogsComponent},
  { path:'page/:id/:seo',component: PageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
