import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MiddleComponent } from './middle/middle.component';
import { LandingPageComponent } from './middle/landing-page/landing-page.component';
import { PageComponent } from './middle/page/page.component';
import { ReviewsComponent } from './middle/reviews/reviews.component';
import { ContactusComponent } from './middle/contactus/contactus.component';
import { DancesComponent } from './middle/dances/dances.component';
import { GaleryComponent } from './middle/galery/galery.component';
import { BlogsComponent } from './middle/blogs/blogs.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import { FormsModule }   from '@angular/forms';
import { SafePipe } from './safe.pipe';
import { PreloadResolver } from './preload-resolver.service';

export function HttpLoaderFactory(httpClient: HttpClient) {
return new TranslateHttpLoader(httpClient, environment.api+'filesystem/getfile/languages/', ".json");

}
@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HeaderComponent,
    FooterComponent,
    MiddleComponent,
    LandingPageComponent,
    PageComponent,
    ReviewsComponent,
    ContactusComponent,
    DancesComponent,
    GaleryComponent,
    BlogsComponent,
    SafePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [ PreloadResolver ,{ provide: Window, useValue: window } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
