<div class="galery">
    <div class="section" *ngFor="let section of galery">
        <div class="title">
            {{section.title[language]}}
        </div>

        <div class="media dib" *ngFor="let file of section.media">
            <div class="mediatitle" *ngIf="file.title && file.title[language]">{{file.title[language]}}</div>
            <div class="images"
                *ngIf="file.file.split('.')[1] && ['jpg','gif','png'].indexOf((file.file.split('.')[1]).toLowerCase()) !=-1 ">
                <!-- <div class="file" [style.background-image]="'url('+api+'filesystem/getfile/'+file.file+')' | safe: 'style'"  ></div> -->
                <img class="file"
                [style.width]="file.size.w ? file.size.w+'px':''" [style.height]="file.size.h ? file.size.h+'px':''"
                    [src]="file.file.indexOf('assets') !=-1 ?file.file:api+'filesystem/getfile/'+file.file+'/medium1'"
                    alt="" srcset="">
            </div>
            <div class="video"
                *ngIf="file.file.split('.')[1] && ['webm','avi','mp4','flv','mkv'].indexOf((file.file.split('.')[1]).toLowerCase()) !=-1 ">
                <div *ngIf="!ios" class="playButtion" (click)="playVideo(file.file)">{{!this.videoPlayers[file.file] ||
                    !this.videoPlayers[file.file]['playing'] ? '▶':'❚❚' }} </div>

                <video *ngIf="!ios" [poster]="api+'filesystem/getfile/'+ file.file+'.jpg/small'" preload="none"
                    [id]="file.file" class="file"
                    [style.width]="file.size.w ? file.size.w+'px':''" [style.height]="file.size.h ? file.size.h+'px':''">
                    <source
                        [src]="(file.file.indexOf('assets') !=-1 ?file.file:'assets/'+ (file.file).replace('-','/'))"
                        type="video/webm">
                    Your browser does not support the video tag.
                </video>
                <video *ngIf="ios" muted playsinline controls
                    [poster]="api+'filesystem/getfile/'+ file.file+'.jpg/small'" preload="none" [id]="file.file"
                    class="file"
                    [style.width]="file.size.w ? file.size.w+'px':''" [style.height]="file.size.h ? file.size.h+'px':''">
                    <source
                        [src]="(file.file.indexOf('assets') !=-1 ?file.file:'assets/'+ (file.file).replace('-','/').replace('Converted','').replace('webm','mp4'))"
                        type="video/mp4">
                    Your browser does not support the video tag.
                </video>

            </div>


            <div class="video" *ngIf="file.file.split('watch?')[1]">
                <iframe class="file"
                [style.width]="file.size.w ? file.size.w+'px':''" [style.height]="file.size.h ? file.size.h+'px':''"
                    [src]="'https://www.youtube.com/embed/'+file.file.split('watch?v=')[1]+'?controls=0&frameborder=0&rel=0&autoplay=0&loop=1' | safe:'resourceUrl'"
                    frameborder="0"
                    allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div>
            <div class="video" *ngIf="file.file.split('shorts/')[1]">
                <iframe class="file"
                [style.width]="file.size.w ? file.size.w+'px':''" [style.height]="file.size.h ? file.size.h+'px':''"
                    [src]="'https://www.youtube.com/embed/'+file.file.split('shorts/')[1]+'?controls=0&frameborder=0&rel=0&autoplay=0&loop=1' | safe:'resourceUrl'"
                    frameborder="0"
                    allow="accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div>

        </div>
    </div>
</div>