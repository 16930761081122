import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-dances',
  templateUrl: './dances.component.html',
  styleUrls: ['./dances.component.scss']
})
export class DancesComponent implements OnInit {
  public api=this.global.api
  public clases=[
    {
      name:{RU:'',EN:'Latin'},
      img:'bildes-pages/a_2.jpg',
      url:''
    },
    {
      name:{RU:'',EN:'High heels'},
      img:'bildes-pages/a_15.jpg',
    },
    {
      name:{RU:'',EN:'Wedding dance'},
      img:'bildes-pages/a_9.jpg',
    },
    {
      name:{RU:'',EN:'Hen party'},
      img:'bildes-pages/a_18.jpg',
    },
  ]
  public hover=0
  public imgON=false
  public language=this.global.language
  constructor(private global:GlobalService) { }

  async ngOnInit() {
    await this.getDancesJSON()
    //console.log(JSON.stringify(this.clases))
  }

  Hover(i){
    if(this.hover!=i){
      this.hover=i;
      this.imgON=false;
      setTimeout(()=>{
       this.imgON=true;
 
      },100)
    }
    
  }

  async getDancesJSON() {
    await this.global.HTTPReq('GET', this.global.api, "filesystem/getfile/layout/dances.json").then((res) => {
      this.clases = res
      //console.log(this.clases)
    })
  
  }
}
