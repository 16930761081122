import { Injectable, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../environments/environment';
import { MessageService } from './message.service';
import { languages } from './interfaces';



@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  public nowScrolling = false;
  public deviceType="pc"
  public imageSize={
    mobile:{
      loading:'20',
      questionImg:'small',
      testIMG:'medium',
      popup:'medium',
      itemCard:'medium',
    },
    tablet:{
      loading:'20',
      questionImg:'medium',
      testIMG:'medium',
      popup:'huge',
      itemCard:'medium',
    },
    pc:{
      loading:'5',
      questionImg:'medium',
      testIMG:'medium',
      popup:'large',
      itemCard:'medium',
    }
  }

  message: any;
  subscription: Subscription;

  thisUrl: Subscription;

  public sliderScelet:[]


  public sidebar={}

  public settings={}

  public cookieNotification = false;
  public tests = []
  public api = environment.api;

  public languages = [ 'RU', "EN"]
  public language = "EN"
  public langChanger = {
    'RU': 'rus',
    'EN': 'eng'
  }

  constructor(private messageService: MessageService, private http1: HttpClient, private router: Router, private translate: TranslateService ) {
    this.getLanguages()
    this.languageChecker().then(() => {
      translate.setDefaultLang(this.language);
    })
    //saņemam globālo valodas mainu ziņu
    this.subscription = this.messageService.getMessage().subscribe(message => {
      this.message = message;
      if (this.message['text'] == 'UpdateLang') {
        this.language = this.message['element'];
        translate.use(this.language)
        //////console.log('Globāli maina' , this.language )
      }
    });



    this.CookieService("GET", "token").then((cookie) => {
      if (cookie && cookie == 1) {
        return
      }
      this.cookieNotification = true
      //////console.log('cepums=',this.cookieNotification)
    })
    if(window.innerWidth<670){
      this.deviceType='mobile'
    }
  }

  getRouterURL() {
    return this.router.url
  }

  async HTTPReq(type, API, location?, element?, AddHeader?): Promise<any> {
    if (!element) {
      element = {}
    }



    // //////console.log('notiek izsauciens uz ' + API)
    return new Promise((resolve, reject) => {
      let result;
      if (type == 'GET') {
        result = this.http1.get(API + location, element,
        ).subscribe(result => {
          //   //////console.log(result);
          let body = result;
          //let body = result;
          if (body) {
            return resolve(body);
          } else {
            return reject(body);
          }
        });
      }
      if (type == 'POST') {
        result = this.http1.post(API + location, element
        ).subscribe(result => {
          //   //////console.log(result);
          let body = result;
          //let body = result;
          if (body) {
            return resolve(body);
          } else {
            return reject(body);
          }
        });
      }
      if (type == 'DELETE') {
        result = this.http1.delete(API + location, element
        ).subscribe(result => {
          //   //////console.log(result);
          let body = result;
          //let body = result;
          if (body) {
            return resolve(body);
          } else {
            return reject(body);
          }
        });
      }

    });



  }
  async CookieService(task, element?) {
    //  //////console.log(task, element)
    if (task == 'SET') {
      if (!element.days) {
        element.days = ''
      }

      return await localStorage.setItem(element.name, JSON.stringify(element.value));

    }
    if (task == 'GET') {
      let value: string = await localStorage.getItem(element)
      if (value && value != '') {
        return value.replace(/['"]+/g, '')
      } else {
        return false
      }

    }
    if (task == 'GETJSON') {
      let value: string = await localStorage.getItem(element)
      if (value && value != '') {
        return JSON.parse(value)
      } else {
        return false
      }

    }

  }

  giveNumberSpaces(numberString) {
    if (typeof (numberString) == 'number') {
      numberString = (numberString).toFixed(0)

    }
    return numberString.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');

  }



  async languageChecker() {
    await this.CookieService("GET", "LANG").then((cookie) => {
      ////console.log(cookie)
      if (!cookie) {
        return
      }
     // this.language = cookie
    })
  }



  isObject(val) {
    return typeof val == "object"
  }
 
  async getSettings(){
    this.settings = await this.HTTPReq("POST", this.api, 'settings/getSettings')

  }
  async detach(json):Promise<any>{
    return await JSON.parse(JSON.stringify(json))
  }

  async scrollTo(x, y, elementToScroll?) {
    let windowToScroll: any = elementToScroll;
    if (!elementToScroll) {
      windowToScroll = window;
    }
    let x1 = 0;
    let y1 = windowToScroll.scrollTop;
    let yDiff = y - y1;
    //console.log(y1, y);
    if (yDiff > 10 || yDiff < -10) {
      windowToScroll.scrollTo(0, y1 + (yDiff / 10));
      if (this.nowScrolling) {
        return;
      }
      setTimeout(() => {
        this.scrollTo(x, y, windowToScroll);
      }, 10);
    }
  }


  async getLanguages(){
    this.HTTPReq("GET", this.api, 'filesystem/getLanguages').then((res)=>{
      //console.log('got Langs')
      this.languages=res
      this.messageService.sendMessage('languagesFeched')
    })
  }

  async iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
 
}