import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import { MessageService } from 'src/app/message.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  @Input('data') public data: any;
  message: any;
  subscription: Subscription;

  public language = 'LV';

  public opened=false;
  public count=0;

  public pages = [];
  public latest = [];

  public allLoaded=false
  public pageID;
  public blogAmount={
    amount:10,
    lastAmount:6
  }

  public keyword=''
public api;
  constructor(private route: ActivatedRoute, private global: GlobalService, private messageService: MessageService) {
    this.api=this.global.api
    


    this.subscription = this.messageService.getMessage().subscribe(async message => {
      this.message = message;
      if (this.message['text'] == 'UpdateLang') {
        if(this.language == this.message['element']) return
        this.language = this.message['element'];
        //console.log('maina', this.language)
        this.pages=[]
        await this.getPages(this.blogAmount.amount,this.pageID,0)
        await this.getLatest(this.blogAmount.lastAmount)
      }
     
    });

    this.language = this.global.language

  }

  async ngOnInit() {
    window.scrollTo(0, 0)
    this.route.paramMap
      .subscribe(params => {
     
        if(!this.data && params['params'][params.keys[1]]){
          this.pageID = params['params'][params.keys[0]];
        }
        if(!this.data && !params['params'][params.keys[1]]){
          this.keyword = params['params'][params.keys[0]];
        }
        
        if(this.data){
          this.blogAmount.amount=this.data.amount
          this.blogAmount.lastAmount=this.data.lastAmount
        }
       
        
        
        this.pages=[]

         this.getPages(this.blogAmount.amount,this.pageID)
         this.getLatest(this.blogAmount.lastAmount)
     

      })
    //console.log(this.data)
  }


  async getPages(amount?,pageId?,skip?){
   if(!amount){
     amount=this.blogAmount.amount
   }
    let para = <any>{type:2,multiplier:amount,skip:skip || skip==0?skip:this.count,keyWord:this.keyword,public:true,lang:this.language}
        this.opened=false
        if (pageId) {
          para = { id: pageId,type:2,multiplier:amount,public:true }
          this.opened=true
        }
    this.global.HTTPReq('POST', this.global.api, 'pages/getPages', para).then((result) => {
   
      // console.log('products atverts='+this.product.id)
      for (let item of result) {
      if (pageId) {
          if (pageId == item.id) {
            this.pages.push(item)
          }
      }else{
        this.pages.push(item)
      } 
      this.count++
    }
   
    if(result.length<1){
      this.allLoaded=true
    }
     // console.log(this.pages)
    })
  }

  async getLatest(amount,keyWord?){
    this.global.HTTPReq('POST', this.global.api, 'pages/getPages', {multiplier:amount,type:2,keyWord,public:true,
    //  lang:this.language
     }).then((result) => {
      this.latest = result
    //  console.log('pēdejie raksti',this.latest)
  })
  }

  async doSomething(task,element?,value?){
    if(task=='fastSearch'){
      this.getLatest(this.blogAmount.lastAmount,this.keyword)
    } 
  }

  totop(){
    window.scrollTo(0, 0)

    //console.log('totop')
    window.scrollTo(0, 0)
    
  }
}
