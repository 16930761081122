import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { GlobalService } from './global.service';




@Injectable()
export class PreloadResolver implements Resolve<any> {
    private files: any[] = [];
    public api ='';
    constructor(private global:GlobalService){
      this.api=this.global.api
    }
    resolve(filesToLoad) {
        return new Promise(async(resolve) => {
            await this.preload(filesToLoad);
            return resolve(true);
        });
    }
    preload(srcs: any[]){
      
        let promises = [];
        for (let i = 0; i < srcs.length; i++) {
          
            let file;
            let source=srcs[i]['src']
            let source2=source.substr(1)
            let fileType=source2.substr(source2.indexOf(".")+1,source2.length)
            let download =true
            for(let DF of this.files){
               if(DF.src ==source){
                 //console.log('ssssse',DF.src, source)
                 download=false
               }
            }

             if(download){

              if(['svg','jpg','png'].indexOf(fileType) != -1){
                //console.log('Got Img')
                ////console.log(source)
                this.files[i] = new Image();
                this.files[i].src = this.api+'filesystem/getfile/'+source;
              }
              if(['mp4','webm','avi','flv'].indexOf(fileType) != -1){
                //console.log('Got Video')
               ////console.log(source)
              //  let newFile = document.createElement('link');
              //  newFile.rel = 'preload';
              //  newFile.as = 'video';
              //  newFile.href = this.api+'filesystem/getfile/'+source;
                 this.files[i] = document.createElement('video');
                 this.files[i].responseType = 'blob';
                 this.files[i].id = 'video';
                 this.files[i].src = this.api+'filesystem/getfile/'+source;

                 if(srcs[i]['assets']){
                  this.files[i].src = '/assets/'+source;

                 }
              }

              
              //console.log(this.files[i])
              promises.push(new Promise(resolve => {
                
                this.files[i].onload = () => {
                  resolve()
                }
              }));
             }

        }
      //  //console.log(this.files)
        return Promise.all(promises);
    }
}