import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  public sent=false
  public emailFields={
    name:'',
    email:'',
    mobile:'',
    subject:'',
    message:'',
    type:'question',
  }
  constructor(private global:GlobalService) { }

  ngOnInit(): void {
  }
  submitMessage(){
    this.global.HTTPReq('POST',this.global.api,'messages/sendMessage',{
      sender:'',
      reciever:1,
      data:this.emailFields
    }).then((res)=>{
      if(res.success){
        this.sent=true
      }
    })
 
  }

}
