

              <ng-container *ngFor="let page of pages">
                  <article class="article" >
                      <!-- IF SINGLE BLOGs -->
                      <div class="row" *ngIf="opened">
                        
                        <title *ngIf="opened">{{page && page.title && page.title[language]? page.title[language]:'Pages'}}</title>
                                  <div class="col-lg-12 fullIMG" [routerLink]="'/blog/'+page.id+'/'+page.seo.url[language]" [style.background-image]="'url('+api+'filesystem/getfile/'+page.image+'/large)' | safe:'style'" alt="">
                                </div>
                          <div class="col-lg-12">
                              <div class="article__title">
                                  <a [routerLink]="'/blog/'+page.id+'/'+page.seo.url[language]" [innerHTML]="page.title[language]"></a>
                              </div>
                              <time class="article__date" pubdate="2019-01-20 19:00" >{{page.timestamp | date:'dd.MM.yyyy'}}</time>
                              <div class="article__text2" >
                                  
                                  <p [innerHTML]="page.desc[language]"></p>

                                  <div style="text-align: right;">
                                        <span>Iesaki:</span>

                                       <a href="https://fb.watch/8dyfyP3ZFw/" target="#">
                                       
                                       </a>

                                        <a  class="link soc" [href]="'https://www.facebook.com/sharer/sharer.php?u=https://www.imdancelondon.com/blog/'+page.id+'/'+page.seo.url[language]" target="_blank">
                                            <svg class="social" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <title>Facebook</title>
                                                <path  d="M14 10l0 1 3 1 -1 2 -2 0c0,2 0,5 0,7l-3 0 0 -7c-3,0 -3,1 -3,-2l3 -1c0,-2 -1,-3 1,-4 1,-2 3,-2 5,-1 0,0 0,1 0,2 -1,0 -3,0 -3,2zm-12 3c0,6 5,11 11,10 6,0 11,-5 10,-11 0,-6 -5,-11 -11,-10 -6,0 -11,5 -10,11z"/>
                                        
                                            </svg>
                                        </a>
                                        <!-- <a class="link soc" [href]="'https://twitter.com/intent/tweet?text=Publikācijas&url=https://www.faccex.com/blog/'+page.id+'/'+page.seo.url[language]" target="_blank"><img width='25px' src="https://cdn3.iconfinder.com/data/icons/inficons-round-brand-set-2/512/twitter-512.png"></a> -->
                                        <!-- <a class="link soc" [href]="'http://www.draugiem.lv/say/ext/add.php?title=Publikācijas&link=https://www.faccex.com/blog/'+page.id+'/'+page.seo.url[language]" target="_blank"><img width='25px' src="assets/img/social/draugiem.png"></a> -->
                                </div>
                              </div>
                              
                          </div>
                      </div>
                    <!-- IF SINGLE BLOGs-->
                    <!-- IF Multiple BLOG -->
                      <!-- <div class="row" *ngIf="!opened">
                            <div class="col-lg-5">
                                <a [routerLink]="'/blog/'+page.id+'/'+page.seo.url[language]">
                                    <img class="img"  [src]="api+'filesystem/getfile/'+page.image+'/medium'"  />
                                </a>
                            </div>
                            <div class="col-lg-7">
                                <div class="article__title">
                                    <a [routerLink]="'/blog/'+page.id+'/'+page.seo.url[language]" [innerHTML]="page.title[language]"></a>
                                </div>
                                <time class="article__date" pubdate="2019-01-20 19:00" >{{page.timestamp | date:'dd.MM.yyyy'}}</time>
                                <div class="article__text" >
                                    <p *ngIf="page.seo"[innerHTML]="page.seo.shortDesc[language]"></p>
                                </div>
                                
                                <a  class="btn  btn--blue" [routerLink]="'/blog/'+page.id+'/'+page.seo.url[language]" [innerHTML]="'blog.readArticle' | translate"></a>
                            </div>
                        </div> -->
                    <!-- IF Multiple BLOG -->


                  </article>
              </ng-container>
              <span class="loadMore" *ngIf="!data">
                  <a *ngIf="!opened && !allLoaded" (click)="getPages()" class="btn  btn--gray loadMorebtn"  [innerHTML]="'blog.loadMore' | translate"></a>
              </span>



                <aside class="sidebar">
                    <div class="search" action="/" method="post">
                        <input class="search__input" type="text" (input)="doSomething('fastSearch')" [(ngModel)]="keyword" [placeholder]="'blog.search' | translate">
                        <button class="search__btn link" type="submit" (click)="count=0" [routerLink]="'/blog/'+keyword">
                            <img src="assets/images/search.png" alt="">
                        </button>
                    </div>

                    <div class="related">
                        <div class="related__title" [innerHTML]="!keyword ? ('blog.newestArticles'  | translate ): ('Found Blogs' )">Jaunākie raksti</div>

                        
                        <ng-container *ngFor="let page of latest">

                            <div (click)="totop()" class="post dib">
                                <div class="related__item">
                                    <div class="related__link" [routerLink]="'/blog/'+page.id+'/'+page.seo.url[language]" [innerHTML]="page.title[language]"></div>
                                    <time class="related__date" pubdate="2019-01-20 19:00">{{page.timestamp | date:'dd.MM.yyyy'}}</time>
                                </div>
                                <a class="blogImage" [routerLink]="'/blog/'+page.id+'/'+page.seo.url[language]">
                                    <img class="blogimg"  [src]="api+'filesystem/getfile/'+page.image+'/medium'"  />
                                </a>
                                <div class="article__text scrollHide" >
                                    <p *ngIf="page.seo"[innerHTML]="page.seo.shortDesc[language]"></p>
                                </div>
                            </div>
                            
                        </ng-container>
  
                    </div>
                </aside>


