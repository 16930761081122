
<div class="pageRoute" (click)="media.largeImage= media.largeImage ? false:true">{{media.pageRoute}}</div>
<div  [class]=" media.largeImage || forceLarge ? 'container2':'container' | safe:'style' ">
    <div *ngIf="media.image" class="mediaPlace">
        <div class="media" [style.background-image]="'url('+api+'filesystem/getfile/'+media.image+(media.largeImage? '':'/huge')+')' | safe: 'style'"  *ngIf="media.image.split('.')[1] && ['jpg','gif','png'].indexOf((media.image.split('.')[1]).toLowerCase()) !=-1 "></div>
 
        <ng-container *ngIf="media.image">
            <video #video [muted]="'muted'" playsinline autoplay  loop="true" type="video/webm" class="media"  *ngIf="media.image.split('.')[1] || ['webm','avi','mp4','flv','mkv'].indexOf((media.image.split('.')[1]).toLowerCase()) !=-1 " >
                <!-- [src]="(media.image.indexOf('assets') !=-1 ?media.image:'assets/'+'filesystem/getfile/'+ media.image)" -->
                <source [src]="(media.image.indexOf('assets') !=-1 ?media.image:'assets/'+ (media.image).replace('-','/').replace('Converted','').replace('webm','mp4'))" type="video/mp4">
                <source [src]="(media.image.indexOf('assets') !=-1 ?media.image:'assets/'+ (media.image).replace('-','/'))"  type="video/webm">
            </video> 
        </ng-container>
        
       
        <!-- <iframe class="media"  id="videosYoutube" style="background-color: #FFFFFF" width="100%" [style.min-height]="'100%'" [src]='"https://www.youtube.com/embed/"+media.image+"?autoplay=1&mute=0&loop=1&showinfo=0&rel=0&modestbranding=0&controls=0" | safe:"resourceUrl"' frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->

    </div>
    <div class="scollarea" *ngIf="media.title || media.shortDesc"></div>
    <div  [class]="'tittlePlace '" *ngIf="media.title || media.shortDesc">
        <div class="title"  [innerHTML]="media.title"></div>
        <div class="shortDesc" [innerHTML]="media.shortDesc"></div>
        
    </div>
    <div class="routerOut">
        <router-outlet></router-outlet>

    </div>

</div>

