<div class="dances" *ngIf="clases">
    <div class="dance link" [routerLink]="'/page/'+dance.url+'/'+dance.name[language]" (mouseover)="Hover(i)"  (mouseleave)="hover=null" *ngFor="let dance of clases;let i = index;">
        <div  class="name dib">{{dance.name[language]}}</div>
    </div>

    <div class="imgBlend" [class.fadeout]="hover==null" *ngIf="imgON || (imgON && hover)" >
        <div class="img" *ngIf="clases[hover] && clases[hover].img" [style.background-image]="'url('+api+'filesystem/getfile/'+clases[hover].img+')' | safe: 'style'"></div>
        <!-- <div class="arrow ">▶</div> -->
    </div>
    

</div>
