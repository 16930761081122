import { Component, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from './message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostListener('window:resize',["event"])
  ckeckw(){
    this.ckeckWidth()
    //console.log(this.width)
  }
  title = 'web';
  public width=window.innerWidth-320
  public showMenu=false
  subscription: Subscription
  constructor(private messageService:MessageService){
    this.ckeckWidth()
    this.subscription = this.messageService.getMessage().subscribe(message => {
      
      if (message['text'] == 'closeMenu') {
        this.showMenu= false
      }
      if (message['text'] == 'openMenu') {
        this.showMenu= true
      }
    });
  }
  ckeckWidth(){
    if(window.innerWidth>992){
      this.width=window.innerWidth-320
    }else{
      this.width=window.innerWidth
    }
  }
}
