import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from '../global.service';
import { mediaI } from '../interfaces';
import { MessageService } from '../message.service';
import { PreloadResolver } from '../preload-resolver.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @HostListener('window:resize',["event"])
  ckeckw(){
    this.setAutoFull()
  }
  @ViewChild("video")
  video: ElementRef;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    //console.log('pop',event)
    this.media= {
      pageRoute:'',
     image: '',
     title:``,
     shortDesc:``,
     largeImage:true
    } 
  }

  public api=this.global.api
  public language=this.global.language
  subscription: Subscription;
  public forceLarge=false
  public media:mediaI={
    pageRoute:'',
   image: '',
   title:``,
   shortDesc:``,
   largeImage:true
  } 

  public preloadFiles = [
    {src:"video/2.mp4",assets:true}
  ]
  public loadingFiles=false
  constructor(private messageService:MessageService,private global:GlobalService, private preloader: PreloadResolver,private ref: ChangeDetectorRef) { 
    this.preload()
    this.subscription = this.messageService.getMessage().subscribe(message => {
       message;
      if (message['text'] == 'UpdateLang') {
        this.language = message['element'];
        //////console.log('Globāli maina' , this.language )
      }
      if (message['text'] == 'mediaChange') {
        this.media={
          pageRoute:'',
         image: undefined,
         title:``,
         shortDesc:``,
         largeImage:true
        } 
        setTimeout(() => {
          this.media = message['element'];
          ref.detectChanges()
        }, 100);
        
        //////console.log('Globāli maina' , this.language )
      }
    });
  }

  ngOnInit(): void {
    setTimeout(()=>{
      //this.video.nativeElement.pause();

    },1000)
    this.setAutoFull()
  }

  async preload() {
    this.loadingFiles=true
   
     // this.preloadFiles.push({ src: })
    
    //console.log(this.preloadFiles)
    //http://localhost:3011/api/filesystem/getfile/bildes-agentiem/1571066380332.png");
    //file.src = this.api+'filesystem/getfile/'+source;
    // "bildes-slaideri/DG_jpg.jpg"
    //url("http://localhost:3011/api/filesystem/getfileFromURL/http%3A%2F%2Fsothebys.ftp.next.ee%2FScoro%2FRealEstate%2F194436%2F4_1_3_7eef0359a523f93996db4aebb94eb99c.png/small2/cover")
    await this.preloader.preload(this.preloadFiles).then(() => {
      this.loadingFiles = false
      this.messageService.sendMessage('loadingDone')
    })
  }

  setAutoFull(){

    if(window.innerWidth<=992){
      this.forceLarge=true
    }else{
      this.forceLarge=false
    }
  }

}
