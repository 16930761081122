<div class="wholePage">
  <div class="burger" (click)="showMenu=showMenu?false:true" >
    {{!showMenu?'☰':'✕'}}
  </div>
  <div class="menu dib" [style.display]=" !showMenu?'none':'inherit'">
    <app-menu></app-menu>
  </div>
  
  <div class="rest dib scrollHide" [style.width]=" '100%'" >
    <app-header></app-header>
    <app-footer></app-footer>
  </div>
</div>

