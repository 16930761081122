import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/global.service';
import { MessageService } from 'src/app/message.service';
import { mediaI } from '../../interfaces';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  subscription: Subscription;
  public api = this.global.api
  public hidePopup=false
  public hide=false
  constructor(private messageService:MessageService,private global:GlobalService) { 

  }
  ngOnInit(): void {
   
  }

  HidePopup(){
    this.hidePopup=true
    setTimeout(()=>{
      this.hide=true
    },1000)
  }
}
