<app-page [id]="61" [title]="'hidden'"></app-page>
<div class="title"></div>
<div class="msg" *ngIf="sent">Message Sent!</div>
<div class="revForm" *ngIf="!sent">
    <div class="title">Message Me Directly</div>
    <div class="inputs">
        <div class="half nameField">
            <div class="fieldTitle">NAME</div>
            <input [(ngModel)]="emailFields.name" class="fullHidden" type="text">
        </div>
        <div class="half nameField">
            <div class="fieldTitle">EMAIL</div>
            <input [(ngModel)]="emailFields.email" class="fullHidden" type="text">
        </div>
        <div class="half nameField">
            <div class="fieldTitle">PHONE</div>
            <input [(ngModel)]="emailFields.mobile" class="fullHidden" type="text">
        </div>
        <div class="half nameField">
            <div class="fieldTitle">SUBJECT</div>
            <input [(ngModel)]="emailFields.subject" class="fullHidden" type="text">
        </div>
        
        <div class="full">
            <div class="fieldTitle">MESSAGE</div>
            <textarea class="fullHidden" name="" [(ngModel)]="emailFields.message" id="" cols="30" rows="10"></textarea>
        </div>

    </div>
    <div class="sendButtion link" (click)="submitMessage()" >SEND</div>
</div>

<div class="line">
    <div class="or">OR</div>
</div>

<div class="info">
    <div >
        For bookings in the studio in South Kensington and online bookings
    </div>
    
    <div class="contact">
        <div class="mobile"><a href="tel: +44 (0)7407 640095"> +44 (0)7407 640095</a></div>
        <div class="email" ><a href="mailto:iveta@imdancelondon.com">iveta@imdancelondon.com</a> </div>
    </div>
</div>
