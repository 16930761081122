import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  public loading=false
  public submited={
    success:false,
    sent:false
  }
  public api=this.global.api
  public reviews=[

  ]
  public review={
    img:'assets/svg/icons/user.svg',
    name:'',
    text:``,
    approoved:false
  }

  public image
  constructor(private global:GlobalService) { 
    //console.log(JSON.stringify(this.reviews))
    this.getGaleryJSON()
   
  }

  ngOnInit(): void {
  }
  async submitReview(){
    this.loading=true
    await this.submit()
    this.review={
      img:'',
      name:'',
      text:``,
      approoved:false
    }
    this.loading=false
  }
 
  async getGaleryJSON() {
    await this.global.HTTPReq('GET', this.global.api, "filesystem/getfile/layout/reviews.json").then((res)=>{
     let approved=[]
      for(let i =0; i< res.length; i++){
        let rev = res[i]
        if(rev.approoved){
          approved.unshift(rev)
        }
      }
      this.reviews=approved
    })

  }
  async uploadImage(element){
    //console.log('upload',element) 
    let filename=new Date().getTime()
    this.review.img=filename+'.jpg'
    for(let file of element.srcElement.files){
      let reader = new FileReader();
      this.image=file.name
      reader.readAsDataURL(file);
      reader.onload = async (buffer) => {
      
       // this.productSkelet.img[i2]=reader.result.split(',')[1]
        let res=await this.global.HTTPReq('POST',this.api,'filesystem/uploadfile/',{
        folder:'reviews',
        file:{
          name: this.review.img,
          value:JSON.stringify(reader.result).split(',')[1]
        },
        
      })
      if(res.success){
        return true
      }
      return false
      };
      
    
    }

  }

  async submit(){
    if(this.image){
    
    }
    
    await this.global.HTTPReq('POST',this.global.api,'users/addReview',{value:this.review}).then((res)=>{
      this.submited.success=res.success

    })
    this.submited.sent=true
  }
  

}
