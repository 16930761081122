<div class="popup" [class.fadeout]="hidePopup" *ngIf="!hide" >
    <div class="middle">
        <div class="left dib">
            <div class="logo">
                    <img class="imgg" src="../../assets/svg/JMDanceLogo.svg" alt="">
            </div>
            <div class="qoute">“When you dance, you can enjoy the luxury of being you.”
                <div class="author">- Paulo Coelho</div>
            </div>
            <div class="buttion link" (click)="HidePopup()">Visit Site</div>
        </div>
        <div class="right dib"  [style.background-image]="'url('+api+'filesystem/getfile/bildes-pages/a_9.jpg)' | safe: 'style'"></div>
    </div>
 
</div>
<app-page *ngIf="hidePopup" [id]="52"></app-page>
